var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "b-card-group",
        { attrs: { deck: "" } },
        [
          _c(
            "b-card",
            {
              staticStyle: { "max-width": "600px", "min-height": "400px" },
              attrs: {
                title: "ICD/diagnosis codes",
                footer: "Source: NIH, National Library of Medicine",
              },
            },
            [_c("icd-auto-complete")],
            1
          ),
          _c(
            "b-card",
            {
              staticStyle: { "max-width": "600px", "min-height": "400px" },
              attrs: { title: "CPT/procedure codes" },
            },
            [_c("cpt-auto-complete")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex mb-4" }, [
      _c("div", { staticClass: "avo-page-header-text" }, [
        _vm._v(" Code Reference "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }