var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "lookup" } },
      [
        _c(
          "b-input-group",
          { staticClass: "search-group" },
          [
            _c("b-input-group-prepend", [
              _c(
                "span",
                { staticClass: "input-group-text" },
                [_c("b-icon", { attrs: { icon: "search" } })],
                1
              ),
            ]),
            _c("b-input", {
              staticClass: "code-search-input",
              attrs: {
                id: "cpt-code",
                placeholder: "Start typing a code or code description",
              },
              on: { keyup: _vm.update },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm.results.length > 0
      ? _c(
          "ul",
          { staticClass: "ml-4 mt-2", attrs: { id: "display" } },
          _vm._l(_vm.results, function (result) {
            return _c("li", { key: result.code }, [
              _c("strong", [_vm._v(_vm._s(result.code))]),
              _vm._v(" - " + _vm._s(result.medium) + " "),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }